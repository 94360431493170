



























import {
  computed,
  defineComponent,
  provide,
  Ref,
  ref,
} from '@vue/composition-api';

import { getValidCSSUnit } from '~/constants/helpers';
import {
  TColorKeys,
  colors,
} from '~/types/fightcamp';
import {
  componentWhiteList,
  PAGE_SECTION_HEIGHT,
} from './helpers';
import { IPageSectionExpectedAttrs } from './types';

export default defineComponent({
  name: 'PageSection',
  inheritAttrs: false,
  setup(_, context) {
    const pageSectionRef = ref() as Ref<HTMLElement>;

    const {
      height,
      minHeight,
      backgroundColor = 'white',
      customBackgroundColor = null,
      inContainer,
      elementId,
      codeId,
      container: expectedContainer,
    } = (context?.attrs ?? {}) as IPageSectionExpectedAttrs;

    const styles = computed(() => ({
      height: getValidCSSUnit(height, 'auto'),
      backgroundColor: customBackgroundColor || colors[backgroundColor as TColorKeys],
      minHeight: getValidCSSUnit(minHeight, ''),
    }));

    const containerClasses = computed(() => ({
      'fc-container-lg': inContainer,
    }));

    provide(PAGE_SECTION_HEIGHT, computed(() => pageSectionRef?.value?.clientHeight));

    const contentContainer = computed(() => (expectedContainer ?? [])
      .filter(({ component }) => componentWhiteList.has(component)));

    return {
      pageSectionRef,
      elementId,
      codeId,
      contentContainer,
      containerClasses,
      styles,
    };
  },
});
