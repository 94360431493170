



































































































import {
  defineComponent, Ref, ref, computed, PropType,
} from '@vue/composition-api';
import Vue from 'vue';
import { FCButton } from '@fc/angie-ui';
import SmoothScroll from 'vue2-smooth-scroll';
import Review, { IReviewProps } from '~/components/Review.vue';
import { usePagination } from '~/composables/usePagination';

Vue.use(SmoothScroll, {
  duration: 500,
  updateHistory: true,
});

export default defineComponent({
  name: 'Reviews',
  components: {
    Review,
    FCButton,
  },
  props: {
    reviews: {
      type: Array as PropType<IReviewProps[]>,
      required: true,
    },
  },
  setup(props) {
    const {
      currentPage, getNextPage, getPreviousPage, pageNumbers, currentPageData, disableNext, disablePrev, getPageClicked,
    } = usePagination(props.reviews, { perPage: 3 });

    const formattedData = computed(() => currentPageData.value.map((review: any) => ({
      codeId: review.content?.codeId || `UserReview${review.content.name}`,
      name: review.content.name,
      title: review.content.title,
      icon: review.content.icon,
      stars: review.content.stars,
      body: review.content.body,
      date: review.content.reviewDate,
      images: review.content.images,
    })));

    function isCurrentPage(page: number | string) {
      return page === currentPage.value;
    }

    // @ts-ignore
    const { $smoothScroll } = useNuxtApp();
    const customerReviews = ref(null) as Ref<HTMLElement | null>;

    function triggerPage(page: any) {
      scrollToTop();
      getPageClicked(page);
    }

    function scrollToTop() {
      $smoothScroll({
        scrollTo: customerReviews.value,
        offset: -100,
        updateHistory: false,
      });
    }

    function triggerNext() {
      scrollToTop();
      getNextPage();
    }

    function triggerPrevious() {
      scrollToTop();
      getPreviousPage();
    }

    return {
      disableNext,
      disablePrev,
      currentPage,
      triggerNext,
      triggerPrevious,
      pageNumbers,
      formattedData,
      isCurrentPage,
      triggerPage,
      scrollToTop,
      customerReviews,
    };
  },
});
