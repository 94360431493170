import { render, staticRenderFns } from "./WebsiteV3Home.vue?vue&type=template&id=1d03a3be&scoped=true&"
import script from "./WebsiteV3Home.vue?vue&type=script&lang=js&"
export * from "./WebsiteV3Home.vue?vue&type=script&lang=js&"
import style0 from "./WebsiteV3Home.vue?vue&type=style&index=0&id=1d03a3be&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d03a3be",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MediaBackground: require('/opt/build/repo/apps/shop/components/storyblok/MediaBackground/MediaBackground.vue').default,SplitView: require('/opt/build/repo/apps/shop/components/storyblok/SplitView/SplitView.vue').default,PageSection: require('/opt/build/repo/apps/shop/components/storyblok/PageSection/PageSection.vue').default,LinkButton: require('/opt/build/repo/apps/shop/components/storyblok/LinkButton/LinkButton.vue').default,ReviewsGrid: require('/opt/build/repo/apps/shop/components/storyblok/ReviewsGrid/ReviewsGrid.vue').default})
