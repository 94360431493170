//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ReviewPercentageBar',
  props: {
    percentage: {
      type: Number,
      default: 100,
    },
    label: {
      type: String,
      default: '5 Star',
    },
  },
};
