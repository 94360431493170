var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.imagesValid)?_c('div',{staticClass:"review-gallery fc-col-span-5 md:fc-col-span-8"},[_c('div',{staticClass:"fc-flex fc-justify-between fc-margin-y-6"},[_c('p',{staticClass:"fc-heading-3 fc-text-black"},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"review-gallery__button-wrapper"},[(_vm.areMoreImgs)?_c('FCButton',{directives:[{name:"track-click",rawName:"v-track-click",value:(("ReviewGallery_" + _vm.getShowCopy)),expression:"`ReviewGallery_${getShowCopy}`"}],attrs:{"button-theme":"button-secondary","button-size":"small","custom-event-name":"renderAll"},on:{"renderAll":_vm.toggleShowAllImages}},[_c('p',{staticClass:"fc-margin-0"},[_vm._v("\n          "+_vm._s(_vm.getShowCopy)+"\n        ")])]):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"review-gallery__image-container fc-margin-bottom-6"},[_vm._l((_vm.firstImages),function(image,$index){return _c('button',{directives:[{name:"track-click",rawName:"v-track-click",value:(("ReviewGallery_" + (image.filename))),expression:"`ReviewGallery_${image.filename}`"}],key:image.id + $index.toString(),staticClass:"review-gallery__image-wrapper fc-col-span-2",on:{"click":function($event){return _vm.openModal(image)}}},[_c('FCImage',{attrs:{"fc-provider":"storyblok","fc-modifiers":{
          isStatic: true,
        },"nuxt-image-props":{
          src: image.filename,
          alt: image.alt,
          sizes: 'xs:200px',
          width: '200',
          height: '200',
          loading: 'lazy',
        },"display-type":"cover"}})],1)}),_vm._v(" "),(_vm.showAll)?_c('transition-group',{staticClass:"review-gallery__image-container fc-col-span-12",attrs:{"name":"fade","tag":"div"}},_vm._l((_vm.remainingImages),function(image,$index){return _c('button',{directives:[{name:"track-click",rawName:"v-track-click",value:(("ReviewGallery_" + (image.filename))),expression:"`ReviewGallery_${image.filename}`"}],key:image.id + $index.toString(),staticClass:"review-gallery__image-wrapper fc-col-span-2",on:{"click":function($event){return _vm.openModal(image)}}},[_c('FCImage',{attrs:{"fc-provider":"storyblok","fc-modifiers":{
            isStatic: true,
          },"nuxt-image-props":{
            src: image.filename,
            alt: image.alt,
            sizes: 'xs:200px, md:275px, lg:350px',
            width: '200',
            height: '200',
            loading: 'lazy',
          }}})],1)}),0):_vm._e()],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }