








import { defineComponent, computed, PropType } from '@vue/composition-api';
import Reviews from '~/components/Reviews.vue';
import ReviewOverview from '~/components//ReviewOverview.vue';
import ReviewGallery from '~/components/ReviewGallery.vue';
import { IReviews, IReviewsGridProps } from './types';

export default defineComponent({
  name: 'ReviewsGrid',
  inheritAttrs: false,
  components: {
    Reviews,
    ReviewOverview,
    ReviewGallery,
  },
  props: {
    rating: {
      type: String,
      required: true,
    },
    ratingTitle: {
      type: String,
      required: true,
    },
    galleryTitle: {
      type: String,
      required: true,
    },
    reviews: {
      type: Array as PropType<IReviews[]>,
      required: true,
    },
  },
  setup(props: IReviewsGridProps) {
    const galleryContent = computed(() => props.reviews.filter((review) => review.content && review.content.images && review.content.images.length !== 0)
      .map((review) => review.content.images).flat());

    return { galleryContent };
  },
});
