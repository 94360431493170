
import { defineComponent } from '@vue/composition-api';
import { isMobileEffect } from '~/effects/matchMedia';
import TestimonialSlide from './TestimonialSlide.vue';
import { ITestimonialData } from './home/TestimonialVideos.vue';

export default defineComponent({
  name: 'TestimonialCarousel',
  components: {
    TestimonialSlide,
  },
  props: {
    testimonialData: {
      type: Array as () => ITestimonialData[],
      required: true,
    },
  },
  setup() {
    const isMobile = isMobileEffect();

    return { isMobile };
  },
});
