
import { metaData } from '~/configuration/metaData';
import { CMSService } from '~/services/storyBlok/cmsService';
import { transformPackageProduct } from '~/utils/transformers';
import { selectFCBaseProduct } from '~/selectors/selectors';
import WebsiteV3Home from '~/components/v3/home/WebsiteV3Home.vue';

export default {
  name: 'Home',
  head: {
    ...metaData.home,
  },
  components: {
    WebsiteV3Home,
  },
  data() {
    return {
      transformedPackageProducts: [],
    };
  },
  provide() {
    return {
      fcPackagesData: this.transformedPackageProducts,
    };
  },
  // TODO very similar pattern to shop chance to refactor?
  async asyncData({ $storyapi, error, store }) {
    const storyBlok = new CMSService({ cmsService: $storyapi });
    try {
      const resp = await storyBlok.get('v2/home', { resolve_relations: 'ProductData.ecommerceProducts' });
      const packageProductsResp = resp?.data?.story?.content?.body[0]?.ecommerceProducts;

      const transformedPackageProducts = packageProductsResp.map(product => {
        const cmsContent = selectFCBaseProduct(product);
        const eCommContent = store.getters.productsCollection;

        return transformPackageProduct({ cmsContent, eCommContent });
      });

      return { transformedPackageProducts };
    } catch (err) {
      error({ message: err?.message });
    }

    return [];
  },
};
