
import { defineComponent } from '@vue/composition-api';
import FCImage from '~/components/FCImage.vue';
import CustomerIcon from '~/components/CustomerIcon.vue';
import { useModal } from '~/composables/useModal';
import { ITestimonialData } from './home/TestimonialVideos.vue';

export default defineComponent({
  name: 'TestimonialSlide',
  components: {
    FCImage,
    CustomerIcon,
  },
  props: {
    testimonial: {
      type: Object as () => ITestimonialData,
      required: true,
    },
  },
  setup(props) {
    function openModal() {
      const { modalShow } = useModal({
        component: () => import('~/components/storyblok/VideoModal/VideoModal.vue'),
        componentProps: props.testimonial.video,
        modalProps: {
          componentName: 'VideoModal',
          width: process.client ? Math.min(400, Math.floor(window?.innerWidth * 0.85)) : 'auto',
          height: process.client ? Math.min(800, Math.floor(window?.innerHeight * 0.90)) : 'auto',
          reset: true,
          classes: 'modal-mobile-full-screen',
        },
      });

      modalShow();
    }

    return {
      openModal,
    };
  },
});
