import { createSocialCardsTags } from '@fc/app-common';
import { inlineScriptSrcGenerator } from '~/configuration/helpers';

export const affirmScript = {
  hid: 'affirm',
  src: inlineScriptSrcGenerator(`
  _affirm_config = {
    public_api_key: '${process.env.NUXT_ENV_AFFIRM_PUBLIC_KEY}',
    script: '${process.env.NUXT_ENV_AFFIRM_URL}',
  };
  (function (l, g, m, e, a, f, b) {
    var d, c = l[m] || {},
      h = document.createElement(f),
      n = document.getElementsByTagName(f)[0],
      k = function (a, b, c) {
        return function () {
          a[b]._.push([c, arguments])
        }
      };
    c[e] = k(c, e, 'set');
    d = c[e];
    c[a] = {};
    c[a]._ = [];
    d._ = [];
    c[a][b] = k(c, a, b);
    a = 0;
    for (b = 'set add save post open empty reset on off trigger ready setProduct'.split(' '); a < b.length; a++) d[
      b[a]] = k(c, e, b[a]);
    a = 0;
    for (b = ['get', 'token', 'url', 'items']; a < b.length; a++) d[b[a]] = function () {};
    h.async = !0;
    h.src = g[f];
    n.parentNode.insertBefore(h, n);
    delete g[f];
    d(g);
    l[m] = c
  })(window, _affirm_config, 'affirm', 'checkout', 'ui', 'script', 'ready');
`),
};

export const metaData = Object.freeze({
  home: {
    title: 'FightCamp | Interactive At-Home Boxing Workouts & Equipment',
    meta: [
      ...(createSocialCardsTags({
        description: 'FightCamp delivers the most exhilarating and effective form of home fitness through interactive boxing workouts, streamed to your device on demand.',
        pageUrl: 'https://joinfightcamp.com/',
        title: 'FightCamp | Interactive At-Home Boxing Workouts & Equipment',
        imageSrc: 'https://a.storyblok.com/f/152150/1000x1000/dbad9d1ec1/couple_punching_2.png',
      }) ?? []),
      {
        name: 'google-site-verification',
        content: 'SSQzaeJ0tTZ7s8rcfYMEXVqymeJKjlJJsndtDYMj6Jk',
      },
    ],
  },
  aboutUs: {
    title: 'FightCamp | About Us',
    meta: [
      ...(createSocialCardsTags({
        description: 'Learn about FightCamp\'s Origin Story and meet our Founders. Getting stronger is hard work, FightCamp makes it fun.',
        pageUrl: 'https://joinfightcamp.com/about-us',
        title: 'FightCamp | About Us',
        imageSrc: 'https://cdn3.joinfightcamp.com/v4/fc-about-us-founders-001.png',
      }) ?? []),
    ],
  },
  financing: {
    title: 'FightCamp | Learn About Financing Your FightCamp Equipment',
    meta: [
      ...(createSocialCardsTags({
        description: 'FightCamp offers easy monthly payments to finance your new gym. Quickly and easily see if you pre-qualify. No hidden fees.',
        pageUrl: 'https://joinfightcamp.com/financing',
        title: 'FightCamp | Learn About Financing Your FightCamp Equipment',
        imageSrc: 'https://cdn.joinfightcamp.com/images/og/fc-og-shop-001.jpg',
      }) ?? []),
    ],
    script: [
      affirmScript,
    ],
  },
  legalTerms: {
    title: 'FightCamp | Legal Terms',
    meta: [
      ...(createSocialCardsTags({
        description: 'Hykso Inc. provides products and services that empower users to train smarter and reach new heights in sports and fitness performance.',
        pageUrl: 'https://joinfightcamp.com/legal-terms',
        title: 'FightCamp | Legal Terms',
        imageSrc: 'https://cdn.joinfightcamp.com/images/og/fc-og-default-001.jpg',
      }) ?? []),
    ],
  },
  privacyPolicy: {
    title: 'FightCamp | Privacy Policy',
    meta: [
      ...(createSocialCardsTags({
        description: 'FightCamp pledges to respect your privacy, be transparent about our data practices, keep your data safe, and let you decide how your information is shared',
        pageUrl: 'https://joinfightcamp.com/privacy-policy',
        title: 'FightCamp | Privacy Policy',
        imageSrc: 'https://cdn.joinfightcamp.com/images/og/fc-og-default-001.jpg',
      }) ?? []),
    ],
  },
  returnPolicy: {
    title: 'FightCamp | Return Policy',
    meta: [
      ...(createSocialCardsTags({
        description: 'You may return any item purchased from FightCamp for refund or exchange for any reason within 30 days, provided the item is unused and it’s original packaging.',
        pageUrl: 'https://joinfightcamp.com/returns',
        title: 'FightCamp | Return Policy',
        imageSrc: 'https://cdn.joinfightcamp.com/images/og/fc-og-default-001.jpg',
      }) ?? []),
    ],
  },
  checkout: {
    title: 'FightCamp | Checkout',
    meta: [
      ...(createSocialCardsTags({
        description: 'Checkout',
        pageUrl: 'https://joinfightcamp.com/shop/checkout',
        title: 'FightCamp | Checkout',
        imageSrc: 'https://cdn.joinfightcamp.com/images/og/fc-og-default-001.jpg',
      }) ?? []),
    ],
    script: [
      affirmScript,
    ],
  },
  confirmation: {
    title: 'FightCamp | Confirmation',
    meta: [
      ...(createSocialCardsTags({
        description: 'FightCamp delivers the most exhilarating and effective form of home fitness through interactive boxing workouts, streamed to your device on demand.',
        pageUrl: 'https://joinfightcamp.com/shop/confirmation',
        title: 'FightCamp | Confirmation',
        imageSrc: 'https://cdn.joinfightcamp.com/images/og/fc-og-default-001.jpg',
      }) ?? []),
    ],
  },
  equipment: {
    title: 'FightCamp | Shop Accessories',
    meta: [
      ...(createSocialCardsTags({
        description: 'Shop Additional Accessories for Your FightCamp Experience',
        pageUrl: 'https://joinfightcamp.com/',
        title: 'FightCamp | Shop Accessories',
        imageSrc: 'https://cdn.joinfightcamp.com/images/og/fc-og-shop-001.jpg',
      }) ?? []),
    ],
  },
  shop: {
    title: 'FightCamp | Shop Our Packages',
    meta: [
      ...(createSocialCardsTags({
        description: 'Learn more about the features and pricing of our different FightCamp packages. Select the package that is right for you.',
        pageUrl: 'https://joinfightcamp.com/shop',
        title: 'FightCamp | Shop Our Packages',
        imageSrc: 'https://cdn.joinfightcamp.com/images/og/fc-og-shop-001.jpg',
      }) ?? []),
    ],
    script: [
      affirmScript,
    ],
  },
  trackers: {
    title: 'FightCamp | Trackers Package',
    meta: [
      ...(createSocialCardsTags({
        description: 'Learn More About the Features and Pricing of the FightCamp Trackers Package.',
        pageUrl: 'https://joinfightcamp.com/shop/products/fightcamp-trackers',
        title: 'FightCamp | Trackers Package',
        imageSrc: 'https://cdn3.joinfightcamp.com/v4/fc-trackers-product-atf-wraps-001.png',
      }) ?? []),
    ],
    script: [
      affirmScript,
    ],
  },
  trackersBag: {
    title: 'FightCamp | Trackers + Bag Package',
    meta: [
      ...(createSocialCardsTags({
        description: 'Learn More About the Features and Pricing of the FightCamp Trackers + Bag Package.',
        pageUrl: 'https://joinfightcamp.com/shop/products/fightcamp-trackers-bag',
        title: 'FightCamp | Trackers + Bag Package',
        imageSrc: 'https://cdn3.joinfightcamp.com/v4/fc-trackers-bag-product-atf-trackers-and-bag-001.png',
      }) ?? []),
    ],
    script: [
      affirmScript,
    ],
  },
  fightcamp: {
    title: 'FightCamp | Package',
    meta: [
      ...(createSocialCardsTags({
        description: 'Learn More About the Features and Pricing of the FightCamp Package.',
        pageUrl: 'https://joinfightcamp.com/shop/products/fightcamp-trackers-bag',
        title: 'FightCamp Package',
        imageSrc: 'https://cdn3.joinfightcamp.com/v4/fc-trackers-bag-product-atf-trackers-and-bag-001.png',
      }) ?? []),
    ],
    script: [
      affirmScript,
    ],
  },
  consoleStarter: {
    title: 'FightCamp | Console',
    meta: [
      ...(createSocialCardsTags({
        description: 'Learn More About the Features and Pricing of the FightCamp Console Package.',
        pageUrl: 'https://joinfightcamp.com/shop/products/fightcamp-console',
        title: 'FightCamp | Console Package',
        imageSrc: 'https://a.storyblok.com/f/152150/1200x933/90a741458e/shop-console.jpg',
      }) ?? []),
    ],
    script: [
      affirmScript,
    ],
  },
  fightcampStarter: {
    title: 'FightCamp | FightCamp Starter',
    meta: [
      ...(createSocialCardsTags({
        description: 'Learn More About the Features and Pricing of the FightCamp Starter Package.',
        pageUrl: 'https://joinfightcamp.com/shop/products/fightcamp-console-bag',
        title: 'FightCamp | FightCamp Starter Package',
        imageSrc: 'https://a.storyblok.com/f/152150/1200x933/bbaac10152/shop-console-bag.jpg',
      }) ?? []),
    ],
    script: [
      affirmScript,
    ],
  },
  whyItWorks: {
    title: 'FightCamp | Uniquely-Interactive Boxing Workouts At Home',
    meta: [
      ...(createSocialCardsTags({
        description: 'See real-time stats, measure your progress, keep track of how you learn and get better at boxing over time. Take your boxing training to the next level.',
        pageUrl: 'https://joinfightcamp.com/why-it-works',
        title: 'FightCamp | Uniquely-Interactive Boxing Workouts At Home',
        imageSrc: 'https://cdn.joinfightcamp.com/images/og/fc-og-workouts-001.jpg',
      }) ?? []),
    ],
  },
  experience: {
    title: 'FightCamp | At-Home Workouts You\'ll Love, Boxing At Home',
    meta: [
      ...(createSocialCardsTags({
        description: 'FightCamp\'s at-home boxing workouts give you competition, motivation, accountability and advanced features you won\'t get from a traditional workout.',
        pageUrl: 'https://joinfightcamp.com/experience',
        title: 'FightCamp | At-Home Workouts You\'ll Love, Boxing At Home',
        imageSrc: 'https://cdn.joinfightcamp.com/images/og/fc-og-experience-001.jpg',
      }) ?? []),
    ],
  },
  whyChooseFightCamp: {
    title: 'FightCamp | Find Out Why FightCamp is For You',
    meta: [
      ...(createSocialCardsTags({
        description: 'FightCamp fits your schedule, your home, and lets you do more in less time. Get started with FightCamp today.',
        pageUrl: 'https://joinfightcamp.com/why-choose-fightcamp',
        title: 'FightCamp | Find Out Why FightCamp is For You',
        imageSrc: 'https://cdn3.joinfightcamp.com/v4/what-is-fightcamp-meta-image.jpg',
      }) ?? []),
    ],
  },
  whatIsFightCamp: {
    title: 'FightCamp | Get the Best Workout in the World with FightCamp',
    meta: [
      ...(createSocialCardsTags({
        description: 'FightCamp combines cutting-edge sensors & elite trainers to create the most effective home workout.',
        pageUrl: 'https://joinfightcamp.com/what-is-fightcamp',
        title: 'FightCamp | Get the Best Workout in the World with FightCamp',
        imageSrc: 'https://cdn3.joinfightcamp.com/v4/why-choose-fightcamp-meta-image.jpg',
      }) ?? []),
    ],
  },
  lifestyle: {
    title: 'FightCamp | On-Demand Boxing Workouts, Your Workout, Your Schedule',
    meta: [
      ...(createSocialCardsTags({
        description: 'Workout at home, on your own time & at your own pace. FightCamp\'s boxing training program is designed for every level boxer.',
        pageUrl: 'https://joinfightcamp.com/lifestyle',
        title: 'FightCamp | On-Demand Boxing Workouts, Your Workout, Your Schedule',
        imageSrc: 'https://cdn.joinfightcamp.com/images/og/fc-og-experience-001.jpg',
      }) ?? []),
    ],
  },
  kickboxing: {
    title: 'FightCamp | Kickboxing Workouts At Home',
    meta: [
      ...(createSocialCardsTags({
        description: 'FightCamp takes kickboxing at home to the next level. Expert kickboxing trainers & premium kickboxing equipment. Kickboxing for beginners and experts.',
        pageUrl: 'https://joinfightcamp.com/kickboxing',
        title: 'FightCamp | Kickboxing Workouts At Home',
        imageSrc: 'https://cdn3.joinfightcamp.com/v4/fc-kickboxing-man-kicking-air-left-005.jpg',
      }) ?? []),
    ],
  },
  android: {
    title: 'FightCamp | Android - Now Available',
    meta: [
      ...(createSocialCardsTags({
        description: 'Fightcamp is Available on Google Play',
        pageUrl: 'https://joinfightcamp.com/android',
        title: 'FightCamp | Android - Now Available',
        imageSrc: 'https://cdn3.joinfightcamp.com/v4/fc-android-jess-jumping-001.png',
      }) ?? []),
    ],
  },
  journal: {
    title: 'FightCamp | Training Journal',
    meta: [
      ...(createSocialCardsTags({
        description: 'Training Journal',
        pageUrl: 'https://joinfightcamp.com/journal',
        title: 'FightCamp | Training Journal',
        imageSrc: 'https://cdn3.joinfightcamp.com/v4/fc-training-journal-001.jpg',
      }) ?? []),
    ],
  },
  careers: {
    title: 'FightCamp | Careers at FightCamp',
    meta: [
      ...(createSocialCardsTags({
        description: 'Our mission is to inspire the fighter within everyone of us. We’re looking for people like you to make our mission a reality.',
        pageUrl: 'https://joinfightcamp.com/careers',
        title: 'FightCamp | Careers at FightCamp',
        imageSrc: 'https://cdn3.joinfightcamp.com/800x0/v4/fc-careers-team-headshot-002.jpg',
      }) ?? []),
    ],
  },
  returns: {
    title: 'FightCamp | FightCamp Returns',
    meta: [
      ...(createSocialCardsTags({
        description: 'We are sad to see you go',
        pageUrl: 'https://joinfightcamp.com/returns',
        title: 'FightCamp | FightCamp Returns',
        imageSrc: 'https://cdn.joinfightcamp.com/images/og/fc-og-experience-001.jpg',
      }) ?? []),
    ],
  },
  helpCenter: {
    title: 'FightCamp | FightCamp Help Center',
    meta: [
      ...(createSocialCardsTags({
        description: 'We are here to help',
        pageUrl: 'https://joinfightcamp.com/help-center',
        title: 'FightCamp | FightCamp Help Center',
        imageSrc: 'https://cdn.joinfightcamp.com/images/og/fc-og-experience-001.jpg',
      }) ?? []),
    ],
  },
  gen2HelpCenter: {
    title: 'FightCamp | FightCamp Help Center',
    meta: [
      { hid: 'robots', name: 'robots', content: 'noindex' },
      ...(createSocialCardsTags({
        description: 'We are here to help',
        pageUrl: 'https://joinfightcamp.com/etmjn',
        title: 'FightCamp | FightCamp Help Center',
        imageSrc: 'https://cdn.joinfightcamp.com/images/og/fc-og-experience-001.jpg',
      }) ?? []),
    ],
  },
  trackersLanding: {
    title: 'FightCamp | FightCamp Trackers',
    meta: [
      ...(createSocialCardsTags({
        description: 'Learn all about our famous FightCamp trackers',
        pageUrl: 'https://joinfightcamp.com/trackers',
        title: 'FightCamp | FightCamp Trackers',
        imageSrc: 'https://cdn.joinfightcamp.com/images/og/fc-og-experience-001.jpg',
      }) ?? []),
    ],
  },
  press: {
    title: 'FightCamp | Press & News',
    meta: [
      ...(createSocialCardsTags({
        description: 'See what publishers, newspapers, and others are saying about FightCamp.',
        pageUrl: 'https://joinfightcamp.com/press',
        title: 'FightCamp | Press & News',
        imageSrc: 'https://cdn.joinfightcamp.com/images/og/fc-og-default-001.jpg',
      }) ?? []),
    ],
  },
  idVerification: {
    title: 'FightCamp | Id Verification',
    meta: [
      ...(createSocialCardsTags({
        description: 'Hykso Inc. provides products and services that empower users to train smarter and reach new heights in sports and fitness performance.',
        pageUrl: 'https://joinfightcamp.com/id-verification',
        title: 'FightCamp | Id Verification',
        imageSrc: 'https://cdn.joinfightcamp.com/images/og/fc-og-default-001.jpg',
      }) ?? []),
    ],
  },
});
