var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{directives:[{name:"track-click",rawName:"v-track-click",value:(("TestimonialSlide__" + (_vm.testimonial.customerName))),expression:"`TestimonialSlide__${testimonial.customerName}`"}],staticClass:"testimonial-slide__button fc-flex fc-padding-0",attrs:{"type":"button"},on:{"click":_vm.openModal}},[_c('div',[_c('FCImage',{attrs:{"fc-provider":"storyblok","rounded":true,"nuxt-image-props":{
        src: _vm.testimonial.videoPreview.url,
        alt: _vm.testimonial.videoPreview.alt,
        sizes: 'xs:200px, md:275px, lg:350px',
        width: '338',
        height: '600',
        loading: 'eager'
      }}})],1),_vm._v(" "),_c('div',{staticClass:"testimonial-slide__play-icon fc-flex fc-justify-center fc-align-center fc-bg"},[_c('svg',{staticClass:"testimonial-slide__play-icon-svg",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none"}},[_c('path',{attrs:{"d":"M7.5 3L19.5 12L7.5 21V3Z"}})])]),_vm._v(" "),_c('div',{staticClass:"testimonial-slide__content fc-flex fc-align-center fc-text-white"},[_c('div',{staticClass:"testimonial-slide__customer-icon"},[_c('CustomerIcon',{attrs:{"image":_vm.testimonial.customerIcon,"border":""}})],1),_vm._v(" "),_c('div',{staticClass:"fc-text-left fc-padding-left-1"},[_c('p',{staticClass:"fc-heading-6"},[_vm._v("\n        "+_vm._s(_vm.testimonial.customerName)+"\n      ")]),_vm._v(" "),_c('p',{staticClass:"fc-text-xsmall"},[_vm._v("\n        "+_vm._s(_vm.testimonial.memberSince)+"\n      ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }