



































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Articles',
  props: {
    quotes: {
      type: Array,
      required: true,
    },
    logos: {
      type: Array,
      required: true,
    },
  },
});
